/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Wrapper from '../layouts/wrapper';
import Section from '../layouts/section';
import constants from '../components/constants';
import postData from '../components/poster';
import fallBackProductList from '../data/fallBackProductList.json';
import Radio from '../components/radio/radio-for-products';
import { GoBack } from '../components/GoBack';
const url = constants.api_url;
 
let disabled_products = [ ];
let active_products = ["167", "138", "9", "301", "300"];
// // let disable_plans = ["1208", "1209"];
// let disable_plans = [ ];

class Products extends Component {
  state = {
    data: [],
    loaded: false,
    yet_to_load: true,
  };


  constructor(props) {
    super(props);
    this.state = {
      data: [],
      products: [],
      plans: [],
      adminList: []
    };
  }
  // componentDidMount() {
  //   // console.log(this.props);
  //   this.setState({
  //     data: [],
  //     products:
  //       this.props.data && this.props.data.products
  //         ? JSON.parse(this.props.data.products)
  //         : [],
  //     plans:
  //       this.props.data && this.props.data.plans
  //         ? JSON.parse(this.props.data.plans)
  //         : [],
  //   });
  // }

  componentDidMount() {
    let me = localStorage.getItem("agent_token");
    let agent_id = localStorage.getItem('agent_id');

    postData.getData(url + "getMe.php?token=" + me).then((data) => {
      if (!data.ok) {
        alert("Failed to fetch agent info");
        return;
      }
      let products =data.me.products || JSON.stringify(active_products);
      // console.log(products);
      this.setState({
        me: data.me,
        loaded: true,
        products : JSON.parse(products),
      });
    });
    let orange_allowedProductsMain = localStorage.getItem('orange_allowedProductsMain') || '[]';
    orange_allowedProductsMain = JSON.parse(orange_allowedProductsMain);
    
    this.setState({
      agent_id : agent_id,
      orange_allowedProductsMain : orange_allowedProductsMain
    })

  }

  doSubmitMain() {
    let me = localStorage.getItem('agent_token');
    let agent_id = localStorage.getItem('agent_id');
     

    postData
      .postData(url + 'saveMe.php?token=' + me, {
        products: JSON.stringify(this.state.products),
        plans: JSON.stringify([]),
        agent_id: agent_id,
      })
      .then(data => {
        localStorage.setItem('orange_agentProducts', JSON.stringify(this.state.products));
        if(agent_id == 1 )
        {
          localStorage.setItem('orange_allowedProductsMain', JSON.stringify(this.state.products || [])) ;
        }

        window.scroll(0, 0);
        window
        .swal('Success', 'Saved successfully!', {
          className: "noflexbtns",
        });
        if (!data.ok) {
          this.setState({
            failure: true,
            success: false,
          });
          return;
        }
        this.setState({
          failure: false,
          success: true,
        });
      })
      .catch(err => {
        this.setState({
          failure: true,
          success: false,
        });
      });
  }

  // productsPlanChanged(e) {
  //   let item = e.target.value;
  //   // console.log(e.target.value);
  //   let {plans} = this.state;
  //   if (plans.indexOf(item) > -1) {
  //     plans.splice(plans.indexOf(item), 1);
  //   } else {
  //     plans.push(item);
  //   }
  //   this.setState({
  //     plans: plans,
  //   });
  // }

  productsChanged(item, type) {
    item = item.replace("prouct_", '');
    // let item = e.target.value;
    // console.log(e.target.value);
    let {products} = this.state;

    if(type)
    {
      products.push(item);
    }else{
      products.splice(products.indexOf(item), 1);
    }
         console.log(products);
    this.setState({
      products: products,
    });
  }


  // componentDidMount() {
  //   let auth = localStorage.getItem('agent_token');
  //   postData
  //     .getData(
  //       url + 'listCompletedAgent.php?submitStatus=pending&token=' + auth,
  //       {},
  //       false,
  //       false,
  //     )
  //     .then(data => {
  //       if (data.ok && data.forms_list) {
  //         this.setState({
  //           data: data.forms_list,
  //           loaded: true,
  //           yet_to_load: false,
  //         });
  //       }
  //     })
  //     .catch(err => {
  //       this.setState({
  //         loaded: true,
  //         yet_to_load: false,
  //       });
  //     });
  // }
  render() {
    // console.log('-------')
    // console.log(fallBackProductList)
    let {products, agent_id} = this.state;
    return (
      <Wrapper>
        <div className="header_main">
          <div className="shell" style={{paddingLeft: 5, paddingRight: 5}}>
            <div className="row">
              <div
                className="col-4"
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <img
                  alt="logo"
                  src={require('../assets/images/logo_top.png')}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{backgroundColor: 'var(--color-mode-bg)'}}>
          <Section className="section--primary">
            <Section.Head cls="p-0">
              <div style={{position: 'relative'}}>
                <h1 className="text-center" style={{minHeight: 'auto'}}>
                  Products I Offer
                </h1>

                <GoBack />
              </div>
            </Section.Head>
            <Section.Body>
              <div
                style={{margin: 8, background: 'none'}}
                className=" ">
                



                {this.state.loaded  ? false : (
                  <div style={{textAlign:"center"}}>
                  <img src={require('../assets/loading.gif')}  />
                  </div>
                ) }
                {this.state.loaded ? <>
                <ul className={'  radio_card radio_card_2  has_bold_li'}>
                        {
                            fallBackProductList.map((item, i)=>{
                                // let disabled =
                                if(disabled_products.indexOf(""+item.productId) > -1){
                                  return false;
                                }else{
                                    // return false;
                                }

                                if(this.state.orange_allowedProductsMain.indexOf(""+item.productId) <  0  && agent_id!=1 && agent_id!=2){
                                  return false;
                                }else{
                                  // return false;
                                }
                                return(
                                  <li
                                  key={item.productId}
                                  className={ "ppg "  }>
                                    
                                    <Radio
                                  name={'prouct_'+item.productId}
                                  className={''}
                                  disabled={active_products.indexOf(''+item.productId) < 0 }
                                  id={'prouct_'+item.productId}
                                  trueFalseMode
                                  trueRepresentative
                                  type={'checkbox'}
                                  defaultChecked={products.indexOf(''+item.productId) > -1 }
                                  value={''+item.productId}
                                  text={item.productName}
                                  
                                  onChange={(a, b, c) => {
                                    console.log(a, b, c);
                                    this.productsChanged(a, b);
                                  }}
                                />
                                   
 
                                 
                                    </li>
                                )

                            })
                        }
                        
                       
                        </ul>

<br />

                        
                        <p className="align-center text-center">
                        <button    className="btn btn-success    btn-rounded "  onClick={()=>{
                            this.doSubmitMain();
                        }}>Save</button>   <Link
                        style={{ padding:15,background: 'var(--color-mode-button)', color: '#fff', borderRadius:50, marginLeft:15, width:55, height:55, display:'inline-block'}}
                        to="/inner">
                        <span className="fa fa-home"></span>
                      </Link>
                        </p>

                        </>
                        : false}


               
               
              </div>
            </Section.Body>

            <Section.Foot></Section.Foot>
          </Section>
        </div>
      </Wrapper>
    );
  }
}

export default Products;
