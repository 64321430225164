/**
 * External dependencies.
 */
 import React, {Component, Fragment} from 'react';
 import cx from 'classnames';
 import constants from '../components/constants';
 import {Link} from 'react-router-dom';
 import fallBackProductList from '../data/fallBackProductList.json';
 import Button from '../components/button/button';
 
 
 let i_ar = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
 class Cart extends Component {
   state = {};
   showDependents() {
     let cartData = this.props.cartData;
     let deps = [
       'spouse',
       'dependent',
       'dependent1',
       'dependent2',
       'dependent3',
       'dependent4',
       'dependent5',
     ];
     return (
       <ul style={{listStyle: 'none', padding: 0, margin: 0}}>
         <li>
           <strong style={{fontSize: 10}}>Dependents:</strong>
         </li>
         {deps.map((item, i) => {
           if (cartData[item + '-check'] && cartData[item + '-full-name']) {
             return (
               <li>
                 <small style={{fontSize: 10}}>
                   {cartData[item + '-full-name']} ({cartData[item + '-date']})
                 </small>
               </li>
             );
           }
           return false;
         })}
       </ul>
     );
   }
 
   getRiderName(id, name) {
     if (!id) return '';
     id = id.split('|');
     let planUnit = id[1];
     id = id[2];
     id = id.replace('rider', '');
     planUnit = planUnit.replace('planUnit', '');
     let namef = '';
     name.plans.map((plan, i) => {
       if ('' + plan.planUnitId == planUnit) {
         plan.riders.map(ri => {
           if ('' + ri.riderUnitId == id) {
             namef = this.getNameInner(ri);
             return;
           }
         });
       }
     });
     return namef;
   }
   componentDidMount() {
     let products_list = fallBackProductList || [];
     let cartData = this.props.cartData;
     let cart_items = [];
     let total = 0;
     products_list.map((pro, i) => {
       let item = 'product_' + pro.productId;
 
       if (!cartData['' + item]) return;
 
       let tmp = {riders: []};
       // hai product Cart me
 
       // get price
       let price = cartData[item + '_price']
         ? cartData[item + '_price']
         : cartData[item.replace('_insurance', '') + '_price'];
       let price_main = price || 0;
 
       price = parseFloat(price_main);
 
       // now get riders
       i_ar.map(ik => {
         if (cartData['' + item + '_rider_' + ik]) {
           // add rider info
           tmp.riders.push({
             name: this.getRiderName(cartData['' + item + '_rider_' + ik], pro),
             cost: parseFloat(
               cartData['' + item + '_rider_' + ik + '_price'] || 0,
             ),
           });
 
           // add rider price
           price += parseFloat(
             cartData['' + item + '_rider_' + ik + '_price'] || 0,
           );
         }
       });
 
       let plan = cartData[item + '_plan']
         ? cartData[item + '_plan']
         : cartData[item.replace('_insurance', '') + '_plan'];
 
       if (!isNaN(price)) {
         total += parseFloat(price);
       }
       let for_ = cartData[item + '_radio'];
       tmp.price_raw = price_main;
       tmp.price_base = price_main;
       tmp.price = price;
       // tmp.plan = plan || '';
       tmp.plan = this.getPlanName(plan, item);
       tmp.product_identifier = item || '';
       tmp.for_ = for_;
       tmp.product_name = pro.productName;
       tmp.show_dependents = for_ === 'Self' || for_ === ' self' ? false : true;
 
       cart_items.push(tmp);
     });
 
     this.setState({
       cart_items: cart_items,
       total: total,
     });
   }
 
   goBack() {
     this.props.onBack && this.props.onBack();
   }
 
   getNameInner(item) {
     if (item.riderName.indexOf(item.unitName) > -1) {
       return item.riderName;
     } else if (item.unitName == item.riderName) {
       return item.unitName;
     } else {
       return item.planName + ' - ' + item.unitName;
     }
   }
 
   getName = function (item) {
     // if(item.planUnitFlag )
     // {
     if (item.planName.indexOf(item.unitName) > -1) {
       return item.planName;
     } else if (item.unitName == item.planName) {
       return item.unitName;
     } else {
       return item.planName + ' - ' + item.unitName;
     }
     // }else
     // {
     //     return item.planName;
     // }
   };
 
   getPlanName(id, item) {
     if (!id) return '';
     id = id.split('|');
     id = id[1];
     id = id.replace('planUnit', '');
     let products_list = fallBackProductList || [];
     let products_list_l = products_list.length;
     // console.log(item)
     item = item.replace('product_', '');
     for (let i = 0; i < products_list_l; i++) {
       if (products_list[i].productId == item) {
         //
         let plans = products_list[i].plans;
 
         let planName = '';
         plans.map((pln, i) => {
           // console.log(pln.planUnitId);
           if (pln.planUnitId == id) {
             planName = this.getName(pln);
           }
         });
         return planName;
       }
     }
     return '-ff' + id;
   }
   render() {
     let cartData = this.props.cartData;
     let routeParams = this.props.routeParams;
     let mode = routeParams.mode;
     console.log(routeParams);
     let url_base = '/mode/';
     if (routeParams.mode == 'payroll') {
       url_base += 'payroll/steps/';
     } else {
       url_base += 'individual/steps';
     }
    //  console.log(url_base);
     return (
       <section className={'  ' + cx('section', ' ')} style={{paddingTop: 0}}>
         <div className="container">
           <div className="card p-2 " style={{padding: 16}}>
             <button className="cartIconBack progress__btn"
               onClick={this.goBack.bind(this)}>
               <i className="ico-angle-left"></i>
             </button>
             <h1 className=" text-center ">Shopping Cart</h1>
             <div className="mb30"></div>
 
             <div className="card shopping-cart shadow_card">
               <div className="card-header bg-dark text-light">
                 <p className="p-3 mb-0">
                   <strong>
                     {cartData.first_name} {cartData.last_name}
                   </strong>
                   <br />
                   {(cartData.state || '').toUpperCase()} -{' '}
                   {cartData.mode === 'payroll'
                     ? cartData.employer || 'Payroll'
                     : 'Bank Draft'}{' '}
                   - {cartData.schedule}
                 </p>
                 <div className="clearfix"></div>
               </div>
               <div className="card-body">
                 {this.state.cart_items && this.state.cart_items.length
                   ? this.state.cart_items.map((pro, i) => {
                       return (
                         <div className="cart_item" key={pro.product_identifier}>
                           <div className="row">
                             <div>
                               <h5 className="product-name">
                                 <strong>{pro.product_name}</strong>
                               </h5>
                               <p>
                                 <small>
                                   {pro.plan} ({pro.for_})
                                 </small>
                                 <br />
                                 {pro.riders && pro.riders.length ? (
                                   <>
                                     <strong>Riders: </strong>
                                     {pro.riders.map(ik => {
                                       return <small>{ik.name}</small>;
                                     })}
                                   </>
                                 ) : (
                                   false
                                 )}
                               </p>
 
                               {pro.show_dependents
                                 ? this.showDependents()
                                 : false}
                             </div>
 
                             <div>
                               {/* <p>${parseFloat(pro.price).toFixed(2)}</p> */}
                             </div>
                           </div>
                           <div>
                             <Link
                               className="pill" to={url_base + (mode == 'payroll' ? '5' : '4')}
                               onClick={() => {
                                //  alert('This feature is work in progress');
                                this.props.onChange(pro.product_identifier)
                               }}>
                               Remove
                             </Link>{' '}
                             /{' '}
                             <Link
                             onClick={e => {
                              
                              this.goBack();
                            }}
                               className="pill"
                               to={url_base + (mode == 'payroll' ? '5' : '4')}>
                               Add Coverage
                             </Link>{' '}
                             /{' '}
                             <Link
                             onClick={e => {
                              
                              this.goBack();
                            }}
                               className="pill"
                               to={
                                 url_base +
                                 (mode == 'payroll' ? '6' : '6') +
                                 '/' +
                                 pro.product_identifier
                               }>
                               Change Coverage
                             </Link>
                           </div>
                         </div>
                       );
                     })
                   : false}
               </div>
               {/* <div className="card-footer">
                 <div className="dflex justify-space-between direction-row">
                   <span>Total</span>
                   <strong>${(this.state.total || 0).toFixed(2)}</strong>
                 </div>
               </div> */}
             </div>
             <div className="mb30"></div>

             <div className="text-center">
             <Button
               onClick={e => {
                 window
                   .swal('SAVE DRAFT' , 'Are you sure you want to do this?', {
                     buttons: ['No', 'Yes, Save as Draft'],
                   })
                   .then(value => {
                     if (value) {
                       
                      let unique_id = localStorage.getItem('unique_identifier');
                        let pending_drafts = localStorage.getItem('pending_drafts');
                        if (!pending_drafts) pending_drafts = '[]';
                        pending_drafts = JSON.parse(pending_drafts);

                        pending_drafts.push(unique_id);
                        localStorage.setItem(
                            'pending_drafts',
                            JSON.stringify(pending_drafts),
                        );
                        localStorage.setItem(
                            'draft_' + unique_id,
                            JSON.stringify(cartData),
                        );
                        localStorage.setItem('unique_identifier', '');
                        localStorage.setItem('step_data5', '');
                        localStorage.setItem('using_agent', '');
                        localStorage.setItem('editMode', '');

                        let agent = localStorage.getItem('using_agent');
                        window.swal('Success', 'Application Saved As Draft!', {
                          className: "noflexbtns",
                        });
                        if (agent) {
                          setTimeout(() => {
                            window.location.href = '/inner/agent/' + agent;
                          }, 2000);
                        } else {
                          setTimeout(() => {
                            window.location.href = '/inner';
                          }, 2000);
                        }


                     }
                   });
               }}
               extraClass="pill lg-pill" noClass>
               Save for Later
             </Button>{' '}
                             /{' '}
             <Button noClass
               onClick={e => {
                 e.preventDefault();
                 this.goBack();
               }}
               extraClass="pill lg-pill orange-pill">
               Complete Enrollment
             </Button>
             </div>

             <div className="mb30"></div>

           <div className=" card shopping-cart shadow_card " style={{}}>
                       
                    <div
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        textAlign: "left",
                        // borderTop: "1px solid grey",
                        marginTop: 12,
                        // paddingTop: 21,
                        // paddingBottom: 21,
                        fontSize: 13,
                      }}>
                      <strong className="policy_sent_to_drop">
                        Mail Policy To
                        <select
                          className="mail_policy_to_select"
                          onChange={e => {
                            this.props.cartDataChange("mail_policy_to", e.target.value);
                          }}
                          value={cartData["mail_policy_to"]}>
                          <option value="Insured">Insured</option>
                          <option value="Agent">Agent</option>
                          {cartData["mode"] == "payroll" ? (
                            <option value="Employer">Employer</option>
                          ) : (
                            false
                          )}
                        </select>
                      </strong>
                      {cartData["mail_policy_to"] ==
                      "Insured" ? (
                        <>
                          {cartData["address"]},{" "}
                          {cartData["city"]},{" "}
                          {cartData["state"]},{" "}
                          {cartData["zip"]}
                        </>
                      ) : (
                        cartData["mail_policy_to"]
                      )}
                    </div>
                  </div>


                  </div>
         </div>
       </section>
     );
   }
 }
 
 export default Cart;
 